import React from "react";
import ContactCTA from "./ContactCTA/ContactCTA";

import "./HomeContent.css";

const HomeContent = () => {
    return (
        <div>
            <p className="title-desktop">Hey, nice to meet you!</p>
            <p className="title-mobile">Hey,<br />nice to meet you!</p>
            <p className="content">My name is Marko and I am a Full Stack Developer based in the Netherlands.</p>
            <div style={{padding: "20px"}}/>
            <ContactCTA />
        </div>

    );
}

export default HomeContent;

import React from 'react'

import ButtonCTA from '../../../common/ButtonCTA/ButtonCTA';
import './MobileCTA.css'

const MobileCTA = () => {
    return (
        <>
            <div >
                <div className='cta-layout-mobile'>
                    <a className='cta-title-mobile' href='/contact'>Let's connect!</a>
                    <ButtonCTA />
                </div>
                <p className='cta-content-mobile' style={{paddingTop: '20px'}}>Do you have any questions, inquiries<br/>or would just like to say hi?</p>
                <p className='cta-content-mobile'>No spam, I promise 🤞</p>
            </div>
        </>
    );
}

export default MobileCTA;
import React from 'react'

import ButtonCTA from '../../../common/ButtonCTA/ButtonCTA';
import './DesktopCTA.css'

const DesktopCTA = () => {
    return (
        <>
            <div>
                <a className='cta-title-desktop' href='/contact'>Let's connect!</a>
                <div className='cta-layout-desktop'>
                    <div className='cta-content-container-desktop'>
                        <p className='cta-content-desktop'>Do you have any questions, inquiries or would just like to say hi?</p>
                        <p className='cta-content-desktop'>No spam, I promise 🤞</p>
                    </div>
                    <ButtonCTA />
                </div>

            </div>
        </>
    );
}

export default DesktopCTA;
import React from 'react'

import useViewport from "../../../UseViewport";
import { isBrowser } from "../../../utils";
import DesktopCTA from './DesktopCTA/DesktopCTA';
import MobileCTA from './MobileCTA/MobileCTA';

const ContactCTA = () => {

    if(isBrowser()) {
        const { width } = useViewport();
        const breakpoint = 640;
    
        return width < breakpoint ? <MobileCTA /> : <DesktopCTA/>
      }
    
      return <div />
}

export default ContactCTA;
import React from 'react'

import './ButtonCTA.css'

const ButtonCTA = () => {
    return (
        <a href="/contact" className='styling-a-tag'>
            <div className="arrow-highlight">
                <p className="arrow-symbol">--></p>
            </div>
        </a>
    );
}

export default ButtonCTA;